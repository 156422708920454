@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

:root {
  --primary-color: #0a192f; /* Update this color as per your preference */
  --font-primary: 'Poppins', sans-serif;
  --transition-time: 0.3s;
  --text-color: #FFFFFF; /* This sets the text color to white */
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  display: flex;
  flex-direction: column;
  font-family: var(--font-primary);
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

footer {
  z-index: 7;
  background: var(--primary-color);
  color: var(--text-color);
  text-align: center;
  padding: 15px 0;
  width: 100%;
  box-shadow: 0px -5px 15px rgba(0, 0, 0, 0.1);
  font-family: var(--font-primary);
  position: relative;
}

footer p {
  margin: 0;
  font-size: 0.9em;
  color: #FFFFFF;
}

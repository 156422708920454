@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

/* :root {
  --primary-gradient: linear-gradient(135deg, #EE9B83 0%, #F6D0B5 100%);
  --shadow-color: rgba(238, 149, 126, 0.3);
  --font-primary: 'Poppins', sans-serif;
  --transition-time: 0.3s;
} */

@keyframes nameAnimation {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(0); }
}

@keyframes lastNameAnimation {
  0% { transform: translateX(100%); }
  100% { transform: translateX(0); }
}

h2 span.name {
  display: inline-block;
  animation: nameAnimation 2s ease-in-out forwards;
  animation-delay: 0s;
}

h2 span.surname {
  display: inline-block;
  animation: lastNameAnimation 2s ease-in-out forwards;
  animation-delay: 0s;
}

main {
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; 
  align-items: center;
  min-height: 100vh;
  font-family: var(--font-primary);
  color: #FFFFFF;
  text-align: center;
  overflow: hidden;
  padding: 20px;
}

.element {
  margin: 30px;
  margin-right: 30px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px 0px #090a0b;
  width: auto;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  transition: transform var(--transition-time);
}

@keyframes slideInIntro {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(0); }
}

@keyframes slideInProj {
  0% { transform: translateX(100%); }
  100% { transform: translateX(0); }
}

.element.intro {
  
  background-color: #E7BB41;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-top: 100px;
  margin-right: 120px;
  padding-left: 0;
  padding-right: 20px; 
  border-radius: 0 10px 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  animation: slideInIntro 2.5s forwards;
  width: calc(100% + 20px);

}



.element.contact:hover {
  transform: scale(1.05);
}

.intro .content {
  margin-right: 80px;
  width: 60%;
}

.proj .content {
  margin-right: 0px;
  width: 600%;
}

.intro .side-video {
  margin-left: 10vw;
  width: 15%;
  height: auto;
}

h2 {
  font-weight: 500;
  font-size: 2.4em;
  margin-bottom: 10px;
  color: #2C3A47;
}

p {
  font-size: 1.1em;
  color: #4B4B4B;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

form input,
form textarea {
  width: 50%;
  padding: 10px;
  margin-bottom: 10px;
  border: 2px solid #218adf;
  border-radius: 5px;
  background-color: #FAFAFA;
}

form button {
  width: 30%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background: #2e4ca7;
  color: #ffffff;
  cursor: pointer;
  transition: background-color var(--transition-time) ease, transform var(--transition-time) ease;
}

form button:hover {
  background: #6e93e3;
  transform: scale(1.05);
}

.about {
  background-color: #D3D0CB;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-left: 100px;

  width: 100%; 
  max-width: 100%; 
  height: 600px;
  padding-left: 0px; 
  padding-right: 90px; 
  border-radius: 10px 0 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  animation: slideInProj 2.5s forwards;
  overflow: hidden; 
  box-sizing: border-box; 
}



.about p {
  font-size: 1.1em;
  color: #4B4B4B;
  margin-bottom: 10px;
}

.section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.section.reverse {
  flex-direction: row-reverse;
}

.text-section {
  width: 45%;
}

.image-section {
  width: 45%;
}

.image-section img {
  width: 100%;
  border-radius: 10px;
}




@media (max-width: 480px) {
  h2 {
    font-size: 1.5em;
  }

  p {
    font-size: 0.9em;
  }

  form input, form textarea {
    width: 100%;
  }

  form button {
    width: 70%;
  }

  .element.intro, .element.projs, .about {
    padding: 0px;
  }

  .intro .content, .proj .content, .about .content {
    width: 100%;
  }
}



.hh2 {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 30px;
  text-align: center;
}



.element.skills {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  padding: 20px;
  background: #44bba4;
  backdrop-filter: blur(5px);
  border-radius: 10px;
}

.skills h2 {
  font-size: 2em;
  color: #333;
  margin-bottom: 20px;
}

.icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.icons > * {
  font-size: 3em;
  color: #2c383f;
  transition: transform 0.3s ease, color 0.3s ease;
}

.icons > *:hover {
  transform: scale(1.2);
  color: #D3D0CB;
}

@media (max-width: 768px) {
  main {
    padding: 10px;
  }

  .element.intro, .element.projs, .about {
    flex-direction: column;
    width: 100%;
    margin: 10px 0;
    padding: 10px;
    margin-top: 60px;
  }
  
  .about{
    height: 290vw;


    background-color: #D3D0CB;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-left: 30px;
  
    width: 100%;  
    padding-left: 10px; 
    padding-right: 0px; 
    border-radius: 10px 0 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    animation: slideInProj 2.5s forwards;
    overflow: hidden; 
    box-sizing: border-box; 
  }

  .intro .content, .proj .content, .about .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: 0;
    align-items: stretch;
    box-sizing: border-box;
  }

  .intro .side-image, .proj .side-image, .about .side-image {
    width: 40%;
    margin: 20px 0;
  }

  .element.contact {
    margin-left: 20px;
  }

  form input, form textarea {
    width: 80%;
  }

  form button {
    width: 50%;
  }
  .hh2{
    text-align: center;
    margin-bottom: 400px;
  }
  .element.intro {
  
    background-color: #E7BB41;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-top: 90px;
    margin-right: 60px;
    padding-left: 0;
    padding-right: 30px; 
    border-radius: 0 10px 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    animation: slideInIntro 2.5s forwards;
    width: calc(100% + 20px);
  
  }
  .intro .side-video {
    margin-top: 20px;
    margin-left: 80px;
    margin-bottom: -20px;
    border-width: 0px;
    width: 30vw;
    height: auto;
  }
  .intro .content{
    padding: 20px;
    margin-left: 75px;
  }
}